import React, { Component } from "react";
import authentication from "../../../msalb2creact";
import Avatar from "react-avatar";
import ContextConstructor from "../../../components/Context/Context";

class AssociadoMenorTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      associates: [],
      error: [],
      loading: true,
      associate: "",
      setassociateId: "",
    };
  }

  async componentDidMount() {
    this.populateAssociateData();
  }

  handleDelete = async (associateId) => {
    var idToken = this.context.idToken;

    const headers = {
      Authorization: `Bearer ${idToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await fetch(`/Profile/DeleteConnectionById?associateId=${associateId}`, {
      headers,
      method: "DELETE",
    });
    //console.log({ response });
    window.location.replace("/");
  };

  getNameCapitalize = (name) => {
    if (name != []) {
      return name
        ?.toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }
    return name;
  };

  render() {
    return (
      <div>
        {this.state.associates.map((associate, i) => (
          <div className="User-Fields">
            <div className="Same-Line-Fields">
              <Avatar
                color={"#E0E0E0"}
                name={associate.name}
                round={true}
                size={40}
                style={{ fontWeight: "bold" }}
              />
              <label
                style={{
                  fontWeight: "800",
                  color: "black",
                  paddingTop: "5px",
                  marginLeft: "10px",
                }}>
                {this.getNameCapitalize(associate.name)}
              </label>
              <input
                type="image"
                onClick={() => this.handleDelete(associate.associateId)}
                src={require("../../../images/Bin.svg")}
                style={{ backgroundColor: "transparent", width: "40px", marginTop: "auto" }}
                alt="remove"
              />
            </div>
            <hr />
            <div className="User-Fields">
              <form>
                <label>Nome</label>
                <input
                  type="text"
                  name="Name"
                  readOnly="readOnly"
                  placeholder={this.getNameCapitalize(associate.name)}
                />
              </form>
            </div>
            <div className="Same-Line-Fields">
              <form>
                <label>NIF</label>
                <input type="text" name="Nif" readOnly="readOnly" placeholder={associate.nif} />
              </form>
              <form className="right-form">
                <label>Nº de Associado</label>
                <input
                  type="text"
                  name="associateID"
                  readOnly="readOnly"
                  placeholder={associate.associate_Card_Number}
                />
              </form>
            </div>
            <br />
          </div>
        ))}
      </div>
    );
  }

  async populateAssociateData() {
    var idToken = this.context.idToken;

    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await fetch("/Profile/GetMymAssociates", { headers });
    if (response.status != "200") {
      this.setState({ error: true, loading: false });
      //           if (response.status === 204) appInsights.trackException({ exception: "Profile no content", severityLevel: 3 });
    } else {
      const data = await response.json();
      this.setState({ error: false, associates: data.mymAssociates, loading: false });
    }
  }
}

AssociadoMenorTab.contextType = ContextConstructor;

export default AssociadoMenorTab;

// async populateAssociateData() {
//     const headers = { Authorization: `Bearer ${authentication.getAccessToken()}`, 'Accept': 'application/json', 'Content-Type': 'application/json' };

//     const response = await fetch('/Profile/GetMymAssociates', {
//         method: 'get',
//         headers: headers
//        });
//     if (response.status != "200") {
//         this.setState({ error: true, loading: false });
// //           if (response.status === 204) appInsights.trackException({ exception: "Profile no content", severityLevel: 3 });
//     }
//     else {
//         const data = await response.json();
//         this.setState({ error: false, associates: data, loading: false });
//     }
// }

//contentStyle={{ "background": "none", "border": "none", "width": "auto", "backgroundColor": "initial" }}
