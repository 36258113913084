
import React from 'react'
import "./loadingPopup.css";
import { Loading } from "../Loading/Loading";

export const LoadingPopup = () => {
    return (
        <div class="container-popup">
            <div class="popup-loading-title">
              
                <div className="loading-popup">
                    <img src={require('../../images/loading.svg')} alt="Imagem de carregamento da página"></img>
                </div>
                <br/>
                <h3>Validação de acesso em curso. Por favor, aguarde.</h3>
            </div>
        </div>
    )
};

