/* COMPONENT IMPORTS*/
import DesktopNavbar from "./Navbar/DesktopNavbar";
import { Sidebar } from "./Sidebar/Sidebar";
import ContextConstructor from "./Context/Context";

/* MODULE IMPORTS */
import { Container } from "reactstrap";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

/*import PopupContainer from './Popups/PopupContainer';*/

class Layout extends Component {
  static displayName = Layout.name;

  render() {
    const { isLoggedIn } = this.context;

    if (isLoggedIn) {
      // Verifica se é o menu "home"
      let classeDiv = this.props.location.pathname === "/" ? "with-background-image" : "";
      let classBeneficiosDiv =
        this.props.location.pathname === "/beneficios"
          ? "beneficios-container"
          : this.props.location.pathname === "/cartaoassociado"
          ? "beneficios-container"
          : "";

      return (
        <div className={classeDiv}>
          <DesktopNavbar />
          <Sidebar className="fixed-sidebar" />
          <Container className={classBeneficiosDiv}>{this.props.children}</Container>
        </div>
      );
    } else {
      return (
        <div>
          <Container>{this.props.children}</Container>
        </div>
      );
    }
  }
}

Layout.contextType = ContextConstructor;

export default withRouter(Layout);
