import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

function ProtectedRoute(WrappedComponent) {
  class ProtectedRouteComponent extends Component {
    static contextType = AuthContext;

    componentDidMount() {
      const { msalInstance, performLoginRedirect } = this.context;
      const accounts = msalInstance.getAllAccounts();
      //   console.log("Accounts no ProtectedRoute - componentDidMount", accounts);

      if (accounts.length === 0) {
        performLoginRedirect();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return withRouter(ProtectedRouteComponent);
}

export default ProtectedRoute;
