import React, { Component } from "react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import getadb2cauth from "./getadb2cauth";
import registerServiceWorker from "./registerServiceWorker";

export const AuthContext = React.createContext();

let auth_redirectUri = "";
class AuthProvider extends Component {
  state = {
    msalInstance: null,
    isLoggedIn: false,
    idToken: "",
    scopes: [],
    redirectUri: "",
  };

  componentDidMount() {
    getadb2cauth().then((config) => {
      auth_redirectUri = config.redirectUri;

      const msalInstance = new PublicClientApplication({
        auth: {
          clientId: config.applicationId,
          authority: `${config.instance}/${config.tenant}/${config.signInPolicy}`,
          knownAuthorities: [`${config.instance}`],
          redirectUri: config.redirectUri,
        },
        cache: {
          storeAuthStateInCookie: true,
        },
      });

      this.setState({ msalInstance, scopes: config.scopes, redirectUri: config.redirectUri });

      msalInstance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            this.handleResponse(response);
          } else {
            this.handleAuthentication();
          }
        })
        .catch((error) => {
          console.error("Error handling redirect promise: ", error);
          // Verifica se o erro é de senha esquecida
          if (error.message.includes("AADB2C90118")) {
            console.log("redirect para reset.....");
            this.redirectToPasswordReset(config);
          } else if (error.message.includes('AADB2C90151') > -1) {
              //utilizaador introduz o c�difo enviado por sms 3 vezes erradas
              window.location.replace('/Response/AADB2C90151');
          }
          else if (error.message.includes('AADB2C90157') > -1) {
              //erro na inserção do codigo de sms e de email
              window.location.replace('/Response/AADB2C90157');
          }
        });

      msalInstance.enableAccountStorageEvents();

      msalInstance.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS
        ) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isLoggedIn && this.state.isLoggedIn) {
      registerServiceWorker();
    }
  }

  handleResponse = (response) => {
    const account = response.account;
    const idToken = response.idToken;

    this.setState({
      isLoggedIn: true,
      idToken,
    });

    this.state.msalInstance.setActiveAccount(account);
  };

  performLoginRedirect = () => {
    const { msalInstance, scopes, redirectUri } = this.state;
    const loginRequest = { scopes, redirectUri: auth_redirectUri };
    msalInstance.loginRedirect(loginRequest);
  };

  handleAuthentication = async () => {
    const { msalInstance, scopes } = this.state;
    const loginRequest = { scopes };

    try {
      const accessTokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      this.setState({
        isLoggedIn: true,
        idToken: accessTokenResponse.idToken,
      });
    } catch (error) {
      this.performLoginRedirect();
    }
  };

  // Método para redirecionar para o fluxo de recuperação de senha
  redirectToPasswordReset = (config) => {
    const { msalInstance } = this.state;
    msalInstance.loginRedirect({
        authority: `${config.instance}/${config.tenant}/B2C_1A_PasswordReset`, // Política de redefinição de senha
      redirectUri: config.redirectUri,
    });
  };

  render() {
    const { children } = this.props;
    const { msalInstance, isLoggedIn, idToken } = this.state;

    return (
      <AuthContext.Provider
        value={{
          msalInstance,
          isLoggedIn,
          idToken,
          performLoginRedirect: this.performLoginRedirect,
        }}>
        {isLoggedIn && children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
