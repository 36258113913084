/* COMPONENT IMPORTS*/
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Poupanca from "../pages/Poupanca/Poupanca";
import Protecao from "../pages/Protecao/Protecao";
import Beneficios from "../pages/Beneficios/Beneficios";
import AdicionaMenor from "../pages/AdicionaMenor/AdicionaMenor";
import Correio from "../pages/Correio/Correio";
import Eleicoes from "../pages/Eleicoes/Eleicoes";
import { Subscricao } from "../components/Subscricao/Subscricao";
import { CartaoAssociado } from "../pages/CartaoAssociado/CartaoAssociado";
import { Notifications } from "../pages/Notifications/Notifications";
import { Fees } from "../pages/Fees/Fees";
import { MaioridadePage } from "./RepresentativeResponse/MaioridadePage";

/*import PopupContainer from './Popups/PopupContainer';*/

import ContextConstructor from "./Context/Context";
import ProtectedRoute from "../ProtectedRoute";
/* MODULE IMPORTS */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";

/* CSS IMPORT */
import "../custom.css";
import { Layout } from "../components/Layout";

class RouteRuler extends Component {
  static displayName = RouteRuler.name;

  render() {
    //console.log("RouteRuler context ", this.context)

    let Maioridade = this.context.selectedUser.age >= 18 ? true : false;
    //console.log("Maioridade ", Maioridade, " age ", this.context.selectedUser.age)

    let isRepresentado = this.context.selectedUser.associateId ? true : false;
    //console.log("isRepresentado ", isRepresentado, " associateId ", this.context.selectedUser.associateId)

    // console.log("this.context.selectedUser.associateId", this.context.selectedUser.associateId);

    if (Maioridade === true && isRepresentado === true) {
      return (
        <React.Fragment>
          <Redirect to="/MaioridadePage" />
          <Route exact path="/profile" component={ProtectedRoute(Profile)} />
          <Route exact path="/AdicionaMenor" component={ProtectedRoute(AdicionaMenor)} />
          <Route exact path="/notificacoes" component={ProtectedRoute(Notifications)} />
          <Route exact path="/MaioridadePage" component={ProtectedRoute(MaioridadePage)} />
          <Redirect to="/MaioridadePage" />
        </React.Fragment>
      );
    } else if (this.context.selectedUser.userState == "CONDICIONADO") {
      /*this.context.selectedUser.userState == "CONDICIONADO" , this.context.selectedUser.hasQuotas === true*/
      return (
        <React.Fragment>
          <Route exact path="/fees" component={ProtectedRoute(Fees)} />
          <Redirect to="/fees" />
          <Route exact path="/profile" component={ProtectedRoute(Profile)} />
          <Route exact path="/AdicionaMenor" component={ProtectedRoute(AdicionaMenor)} />
          <Route exact path="/notificacoes" component={ProtectedRoute(Notifications)} />
        </React.Fragment>
      );
    } else if (
      this.context.selectedUser.userState == "NOTASSOCIATE" ||
      this.context.selectedUser.status == "NATIVO"
    ) {
      return (
        <React.Fragment>
          <Route exact path="/" component={Home} />
          <Route exact path="/profile" component={ProtectedRoute(Profile)} />
          <Route exact path="/AdicionaMenor" component={ProtectedRoute(AdicionaMenor)} />
          <Route exact path="/notificacoes" component={ProtectedRoute(Notifications)} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Route exact path="/" component={ProtectedRoute(Home)} />
          <Route exact path="/profile" component={ProtectedRoute(Profile)} />
          <Route exact path="/poupanca" component={ProtectedRoute(Poupanca)} />
          <Route exact path="/protecao" component={ProtectedRoute(Protecao)} />
          <Route exact path="/beneficios" component={ProtectedRoute(Beneficios)} />
          <Route exact path="/subscricao" component={ProtectedRoute(Subscricao)} />
          <Route exact path="/cartaoassociado" component={ProtectedRoute(CartaoAssociado)} />
          <Route exact path="/fees" component={ProtectedRoute(Fees)} />
          <Route exact path="/notificacoes" component={ProtectedRoute(Notifications)} />
          <Route exact path="/correio" component={ProtectedRoute(Correio)} />
          <Route exact path="/AdicionaMenor" component={ProtectedRoute(AdicionaMenor)} />
          <Route exact path="/eleicoes" component={ProtectedRoute(Eleicoes)} />
        </React.Fragment>
      );
    }
  }
}

//Add the context to the Component
RouteRuler.contextType = ContextConstructor;

export default withRouter(RouteRuler);
