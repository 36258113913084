/* MODULE IMPORTS */
import React, { Component } from "react";
import ContextConstructor from "../Context/Context";

/* CSS IMPORT */
import "./NotificationBanner.css";
import { get } from "http";

class NotificationBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsList: [],
      hasUnpaidQuota: false,
      hasNotification: false,
      page: this.props.page,
    };
  }

  componentDidMount() {
    // console.log("Context TopNot ", this.context);

    if (
      this.context.selectedUserID !== "" &&
      this.context.selectedUserID !== undefined &&
      this.context.selectedUserID !== null
    ) {
      //this.getQuotasState();
      this.populateNotifications();
    }
  }

  render() {
    const quotaSection = (
      <div
        className="notification-banner-section"
        style={this.state.page == "profile" ? { width: "100%" } : {}}>
        <img src={require("../../images/alertaCircle.png")} width="26px" />
        <span>Encontra-se com a quota associativa por liquidar.</span>
      </div>
    );
    const items = this.state.notificationsList.map((notification, i) => {
      return (
        <div
          className="notification-banner-section"
          key={i}
          style={this.state.page == "profile" ? { width: "100%" } : {}}
          onClick={() => {
            this.clickedNotification(notification.id);
          }}>
          <img src={require("../../images/mailUnopened.png")} width="26px" />
          <span>{notification.topic}</span>
        </div>
      );
    });
    if (this.state.notificationsList.length !== 0) {
      return (
        <div className="notification-banner-container">
          {/*DESCOMENTAR PARA VOLTAR A TER TOP NOTIFICATION DE QUOTAS this.state.hasUnpaidQuota ? quotaSection : <div/>*/}
          {items}
        </div>
      );
    } else
      return (
        <div className="no-top-notification">
          {/*DESCOMENTAR PARA VOLTAR A TER TOP NOTIFICATION DE QUOTAS  this.state.hasUnpaidQuota ? quotaSection : <div/>*/}
        </div>
      );
  }

  async clickedNotification(id) {
    var idToken = this.context.idToken;
    const headers = {
      Authorization: `Bearer ${idToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    await fetch("/Notifications/DismissTopNotification?Id=" + id, {
      headers,
      method: "POST",
      //body: JSON.stringify({
      //    id: id
      //})
    }).catch((error) => {
      // console.log("Error DismissNotifications ", error);
    });
    //.then(response => {
    //    return response.json();
    //})
    //.then(res => {
    //    console.log("Dismiss res: ", res)
    //})

    this.populateNotifications();
  }

  async getQuotasState() {
    try {
      await new Promise((r) => setTimeout(r, 1));
      var idToken = this.context.idToken;
      const headers = { Authorization: `Bearer ${idToken}` };
      const response = await fetch("/Quotas/GetFees", { headers });
      const data = await response.json();
      data.totalValue == "0"
        ? this.setState({ hasUnpaidQuota: false })
        : this.setState({ hasUnpaidQuota: true });
    } catch (err) {
      console.error(err);
    }
  }

  async populateNotifications() {
    try {
      var clientId = this.context.selectedUserID;
      var idToken = this.context.idToken;

      const headers = { Authorization: `Bearer ${idToken}` };
      const response = await fetch("/Notifications/GetTopNotifications?ClientId=" + clientId, {
        headers,
      });

      const data = await response.json();

      function hasRead(object) {
        let a = false;
        object.forEach(function (value) {
          if (value.isRead == false) a = true;
        });
        return a;
      }

      data.sort((a, b) => {
        if (a.priority > b.priority) return 1;
        else return -1;
      });

      this.setState({
        notificationsList: data,
        hasNotification: hasRead(data),
      });
    } catch (err) {
      console.error(err);
    }
  }
}

NotificationBanner.contextType = ContextConstructor;

export default NotificationBanner;
