/* COMPONENT IMPORTS*/
import ProductDetails from "../../components/ProductDetails/ProductDetails";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import { withRouter } from "react-router";
import React, { Component } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import "./tablestyling.css";
import "pure-react-carousel/dist/react-carousel.es.css";

class SavingNewItemsMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null**/
      actualDateAVOA: null,
      startDateAVOA: null,
      endDateAVOA: null,
      flagAVOA: false,
    };
  }

  componentDidMount() {
    this.getAVOApopup();
    //this.GetBlackWeekDates();
  }

  createSubscription(item) {
    var data = {
      subscriptionID: null,
      subscriptionSerieID: null,
      canReapply: false,
      canReinforce: false,
      /**actualDate: null,
            startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null**/
      actualDateAVOA: null,
      startDateAVOA: null,
      endDateAVOA: null,
      flagAVOA: false,
    };
    this.props.history.push({
      pathname: "/subscricao",
      state: { params: data, modalidade: item },
    });
  }

  openDetalhesPopup(index) {
    //faz triger do click para abrir os detalhes especificos
    document.getElementById("popupDetalhesTrigger" + index).click();
  }

  render() {
    let itemsToRender = this.props.savingsNewList.filter((item) => item.subscriptionsNumber == 0);
    let numItems = itemsToRender.length;

    return (
      <React.Fragment>
        <div className="carousel-wrapper carousel-subscricao-nova-modalidade">
          <div className="consulta-detalhes-clicaveis-poupanca">
            {itemsToRender.map((item, index) => (
              <ProductDetails
                key={index}
                elementID={"popupDetalhesTrigger" + index}
                productID={item.productID}
                subscriptionType="product"
                productName={item.productName}
                subscriptionActive={false}
              />
            ))}
          </div>
          <CarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={500}
            totalSlides={numItems}
            visibleSlides={1}>
            <Slider>
              {itemsToRender.map((item, index) => (
                <Slide key={index} index={index}>
                  <div key={index} className="card-new card-new-mobile">
                    <div className="card-new-table">
                      <div className="card-new-title">
                        <div
                          className="serie-name-wrapper"
                          style={{ position: "initial !important" }}>
                          <div
                            onClick={this.openDetalhesPopup.bind(this, index)}
                            className="tableCapitalCerto-title-inactive">
                            {item.productName}
                          </div>
                        </div>
                      </div>
                      <div className="tableCapitalCerto-amount">
                        <div className="card-new-table-value">0 €</div>
                        <div className="tableCapitalCerto-subtitle-unactive">Montante entregue</div>
                      </div>
                      <div className="tableCapitalCerto-amount">
                        <div className="tableCapitalCerto-boldvalue">-</div>
                        <div className="tableCapitalCerto-subtitle-unactive">
                          Rendimento acumulado Bruto
                        </div>
                      </div>
                      <div
                        id="saving-create-subscription-button"
                        onClick={this.createSubscription.bind(this, item)}>
                        {/*< img src={require('../../images/newseries.png')} style={{ "cursor": "pointer" }} height="50px" width="50px"></img>teste*/}
                        <img src={require("../../images/newseries.png")}></img>
                        <div className="createSubscriptionMobile-text">Subscrever</div>
                      </div>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
            <DotGroup />
          </CarouselProvider>
        </div>
      </React.Fragment>
    );
  }

  /**async GetBlackWeekDates() {

        const atoken = authentication.getAccessToken();

        const headers = { Authorization: `Bearer ${atoken}` };

        const response = await fetch("/Elections/GetBlackWeekDates", {

            headers,

        });

        if (response.status !== 200) {

        } else {

            const data = await response.json();


            this.setState({
                actualDate: data.actualDate,
                startDateBFTeaser: data.startDateBFTeaser,
                endDateBFTeaser: data.endDateBFTeaser,
                startDateBF: data.startDateBF,
                endDateBF: data.endDateBF,
            });
        }

    }**/
  async getAVOApopup() {
    var idToken = this.context.idToken;
    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await fetch("/Elections/GetAVOADates", {
      headers,
    });

    if (response.status !== 200) {
    } else {
      const data = await response.json();

      this.setState({
        actualDateAVOA: data.actualDateAVOA,
        startDateAVOA: data.startDateAVOA,
        endDateAVOA: data.endDateAVOA,
        flagAVOA: data.flagAVOA,
      });
    }
  }
}

SavingNewItemsMobile.contextType = ContextConstructor;

export default withRouter(SavingNewItemsMobile);
