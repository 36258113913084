/* COMPONENT IMPORTS*/
import { Logout } from "../Logout/Logout";
import { Sidebar } from "../Sidebar/Sidebar";
import ContextConstructor from "../Context/Context";

/* MODULE IMPORTS */
import Avatar from "react-avatar";
import Popup from "reactjs-popup";
import { withRouter } from "react-router-dom";
import Drawer from "react-motion-drawer";
import { NavLink } from "react-router-dom";
import React, { Component } from "react";

/* SAME FOLDER IMPORTS*/
import { QuotasStatus } from "./QuotasStatus";
import GestorPopup from "../Sidebar/GestorPopup";
import MAVOAPopup from "../Sidebar/MAVOAPopup";
import MusicaolicosPopup from "../Sidebar/MusicaolicosPopup";
import CampanhaMenoresPopup from "../Sidebar/CampanhaMenoresPopup";

/* CSS IMPORT */
import "./Navbar.css";
import Profile from "../../pages/Profile/Profile.css";
import { isNullOrUndefined } from "util";

class DesktopNavbar extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
  }

  state = {
    openLeft: false,
    openRight: false,
    drawerStyle: `
        {
            "background": "#F9F9F9",
            "boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
        }`,
    relativeWidth: false,
    widthLogoutMenu: 380,
    widthSideMenu: 380,
    noTouchOpen: false,
    noTouchClose: false,
    name: [],
    /*profile: [],*/
    terms: [],
    hasNotification: false,
    loading: true,
    showVotarSide: true,
    actualDate: null,
    startDate: null,
    endDate: null,
    //canVote: null,
    //startDateBFTeaser: null,
    //endDateBFTeaser: null,
    //startDateBF: null,
    //  endDateBF: null,
    actualDateAVOA: null,
    endDateAVOA: null,
    startDateAVOA: null,
    associates: [],
    selectedUserClientID: "",
    isActualUser: "",
  };

  routeChange() {
    let path = "/profile";
    this.props.history.push(path);
  }

  componentDidMount() {
    this.getAVOApopup();
    this.getTermsLink();
    this.populateProfileData();
    //this.getNotificationStatus();
    //this.getManagerInfo();

    setTimeout(
      function () {
        this.getManagerInfo();
      }.bind(this),
      2000,
    );

    if (
      this.context.selectedUserID !== "" &&
      this.context.selectedUserID !== undefined &&
      this.context.selectedUserID !== null &&
      this.manager &&
      this.manager.employeeNumber === "" &&
      this.manager.employeeNumber === null
    ) {
      this.getNotificationStatus();
    }
  }

  setWidth = (e) => {
    this.setState({
      width: Number(e.target.value) || e.target.value,
    });
  };

  setTouch = (e) => {
    this.setState({
      [e.target.name]: !e.target.checked,
    });
  };

  setDrawerStyle = (e) => {
    e.preventDefault();
    this.setState({
      drawerStyle: this.drawerStyleRef.value,
    });
  };

  getInitials = (name) => {
    if (name != []) {
      var names = name.split(" ");

      let x = names[0][0] + " " + names[names.length - 1][0];

      return x;
    }
    return name;
  };

  getNameCapitalize = (name) => {
    if (name != []) {
      return name
        ?.toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }
    return name;
  };

  utf8Decode(utf8String) {
    if (typeof utf8String != "string")
      throw new TypeError("parameter ‘utf8String’ is not a string");
    // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
    const unicodeString = utf8String
      .replace(
        /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, // 3-byte chars
        function (c) {
          // (note parentheses for precedence)
          var cc =
            ((c.charCodeAt(0) & 0x0f) << 12) |
            ((c.charCodeAt(1) & 0x3f) << 6) |
            (c.charCodeAt(2) & 0x3f);
          return String.fromCharCode(cc);
        },
      )
      .replace(
        /[\u00c0-\u00df][\u0080-\u00bf]/g, // 2-byte chars
        function (c) {
          // (note parentheses for precedence)
          var cc = ((c.charCodeAt(0) & 0x1f) << 6) | (c.charCodeAt(1) & 0x3f);
          return String.fromCharCode(cc);
        },
      );
    return unicodeString;
  }

  closeMenuFunction = () => {
    this.setState({
      openLeft: false,
      openRight: false,
    });
  };

  //switchToAssociateUser
  switchToAssociateUser = (associate) => {
    //Update name on current component
    this.setState({
      openLeft: false,
      openRight: false,
      /*name: associate.name*/
      name: this.getNameCapitalize(associate.name),
    });

    //Change selectedUser on context
    this.context.changeselectedUserID(associate);

    //Update the Manager Contact Request
    // console.log("Alterou manager info");
    this.getManagerInfo();
  };

  profileButtons() {
    if (this.context.tokenUser === this.context.selectedUserID) {
      return (
        <div>
          <NavLink to="/profile" style={{ textDecoration: "none" }}>
            <div onClick={this.closeMenuFunction} className="dadosconta-button">
              <div>Os seus dados</div>
              <img src={require("../../images/pref.png")} alt="" width="26px" height="´26px" />
            </div>
          </NavLink>
          <NavLink to="/profile?activeTab=tab2" style={{ textDecoration: "none" }}>
            {/*<div desativar para mostrar o botão Gerir agregado familiar*/}
            {/*    onClick={this.closeMenuFunction}*/}
            {/*    className="dadosconta-button"*/}
            {/*>*/}
            {/*    <div>Gerir Agregado Familiar</div>*/}
            {/*    <img*/}
            {/*        src={require("../../images/userGroup.png")}*/}
            {/*        alt=""*/}
            {/*        width="26px"*/}
            {/*        height="´26px"*/}
            {/*    />*/}
            {/*</div>*/}
          </NavLink>
        </div>
      );
    } else {
      return "";
    }
  }

  buttonAddMenor() {
    if (this.context.tokenUser === this.context.selectedUserID) {
      return (
        <NavLink to="/AdicionaMenor" style={{ textDecoration: "none" }}>
          <div onClick={this.closeMenuFunction} className="adicionaassociadomenor">
            <img src={require("../../images/add_person.png")} alt="" width="26px" height="´26px" />
            <div style={{ marginTop: "15px", marginLeft: "7px" }}>
              Adicionar Criança ou Jovem Associado
            </div>
          </div>
        </NavLink>
      );
    } else {
      return "";
    }
  }

  displayAssociadosMenores() {
    let menores = (
      <div>
        {this.context.mymUsers
          .filter((associate) => {
            return associate.clientID !== this.context.selectedUserID;
          })
          .map((associate, i) => {
            if (associate.associateState == "CONDICIONADO") {
              return (
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <div onClick={() => this.switchToAssociateUser(associate)}>
                    <hr />
                    <div style={{ textAlign: "left" }}>
                      <Avatar
                        color={"#E0E0E0"}
                        name={this.getNameCapitalize(associate.name)}
                        round={true}
                        size={40}
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          paddingTop: "5px",
                          marginLeft: "10px",
                          display: "inline",
                        }}>
                        {this.getNameCapitalize(associate.name)}
                        {/*{associate.name}*/}
                      </label>
                      <img
                        src={require("../../images/alertaCircle.png")}
                        width="20px"
                        height="20px"></img>
                    </div>
                    <hr />
                  </div>
                </NavLink>
              );
            } else {
              return (
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <div onClick={() => this.switchToAssociateUser(associate)}>
                    <hr />
                    <div style={{ textAlign: "left" }}>
                      <Avatar
                        color={"#E0E0E0"}
                        name={this.getNameCapitalize(associate.name)}
                        round={true}
                        size={40}
                      />
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          paddingTop: "5px",
                          marginLeft: "10px",
                          display: "inline",
                        }}>
                        {this.getNameCapitalize(associate.name)}
                        {/*{associate.name}*/}
                      </label>
                    </div>
                    <hr />
                  </div>
                </NavLink>
              );
            }
          })}
      </div>
    );

    const buttonAddMenor = () => {
      if (this.context.tokenUser === this.context.selectedUserID) {
        return (
          <NavLink to="/AdicionaMenor" style={{ textDecoration: "none" }}>
            <div onClick={this.closeMenuFunction} className="adicionaassociadomenor">
              <img
                src={require("../../images/add_person.png")}
                alt=""
                width="26px"
                height="´26px"
              />
              <div style={{ marginTop: "15px", marginLeft: "7px" }}>
                Adicionar Criança ou Jovem Associado
              </div>
            </div>
          </NavLink>
        );
      } else {
        return "";
      }
    };

    return (
      <div>
        {menores}
        {buttonAddMenor()}
      </div>
    );
  }

  render() {
    const {
      drawerStyle: stringDrawerStyle,
      openLeft,
      openRight,
      noTouchOpen,
      name,
      noTouchClose,
    } = this.state;

    let drawerStyle = {};
    try {
      drawerStyle = JSON.parse(stringDrawerStyle);
    } catch (err) {
      console.error("Error parsing JSON: ", err);
    }

    const drawerProps = {
      overlayColor: "rgba(255,255,255,0.6)",
      drawerStyle,
    };

        let popupCampanhaMenoresCarolina = (
            <Popup
                className="popupCampanhaMenoresCarolina"
                trigger={
                    <div id="popupCampanhaMenoresCarolina" style={{ display: "none" }}></div>
                }
                modal
                closeOnDocumentClick
                contentStyle={{ background: "none", border: "none" }}
            >
                {(close) => (
                    <CampanhaMenoresPopup
                        close={close}
                    />
                )}
            </Popup>
        );

        let popupMusicaolicos = (
            <Popup
                className="popupMusicaolicos"
                trigger={
                    <div id="popupMusicaolicos" style={{ display: "none" }}></div>
                }
                modal
                closeOnDocumentClick
                contentStyle={{ background: "none", border: "none" }}
            >
                {(close) => (
                    <MusicaolicosPopup
                        close={close}
                    />
                )}
            </Popup>
        );

    let popupGestor = (
      <Popup
        trigger={<div id="popupGestorTrigger" style={{ display: "none" }}></div>}
        modal
        closeOnDocumentClick
        contentStyle={{ background: "none", border: "none" }}>
        {(close) => (
          <GestorPopup
            manager={this.state.manager}
            close={close}
            hasManager={this.state.hasManager}
          />
        )}
      </Popup>
    );

    let popupMAVOA = (
      <Popup
        className="popupPrimaveraSound"
        trigger={<div id="popupMAVOATrigger" style={{ display: "none" }}></div>}
        modal
        closeOnDocumentClick
        contentStyle={{ background: "none", border: "none" }}>
        {(close) => <MAVOAPopup close={close} />}
      </Popup>
    );

        return (

            <div>
                {popupGestor}
                {popupMusicaolicos}
                {popupMAVOA}
                {popupCampanhaMenoresCarolina}
               
                <div
                    className={
                        "navbar-div " +
                        (this.state.openRight || this.state.openLeft ? "absolute" : "")
                    }
                >
                    <div className="nav-logo">
                        <a href="/">
                            <img
                                src={require("../../images/logoMobile.png")}
                                alt=""
                                height="45px"
                                className=""
                            ></img>
                        </a>
                    </div>
                    <ul className="nav-links">
                        <li>
                            <div className="drawer-wrapper">
                                {!openLeft && (
                                    <Drawer
                                        right
                                        width={this.state.widthLogoutMenu}
                                        {...drawerProps}
                                        open={openRight}
                                        onChange={(open) => this.setState({ openRight: open })}
                                        noTouchOpen={noTouchOpen}
                                        noTouchClose={noTouchClose}
                                        onClick={this.setProfileName}
                                        className="drawerStyle"
                                        overlayClassName="overlayStyle"
                                    >
                                        <div className="sidedrawer">
                                            <Logout />
                                            <div className="dadosconta">
                                                <Avatar
                                                    color={"#FF5800"}
                                                    name={this.state.name}
                                                    round={true}
                                                    size={120}
                                                />
                                                <div className="username">{/*{this.context.selectedUser.name}*/}
                                                    {this.state.name}
                                                    {/*{this.context.selectedUser.length > 0 && this.getNameCapitalize(this.context.selectedUser[0].name)}*/}
                                                </div>
                                                <QuotasStatus
                                                    selectedUserProfile={this.context.selectedUser}
                                                    closeMenuFunction={this.closeMenuFunction}
                                                ></QuotasStatus>

                        {this.profileButtons()}

                        {/* {this.displayAssociadosMenores()}*/}
                      </div>
                      <div className="downloads-wrapper">
                        <div className="downloads-sidedrawer-text">DOWNLOADS</div>
                        <div style={{ display: "flex", marginTop: "30px" }}>
                          <img
                            style={{ marginRight: "18px" }}
                            src={require("../../images/pdfIcon.png")}
                            alt=""
                            height="26px"
                            width="26px"
                          />
                          <div>
                            <div className="glossary-title">Termos e Condições</div>
                            <a
                              className="glossary-download-button"
                              href={this.state.terms}
                              download="termosecondicoes.pdf"
                              target="_blank"
                              rel="noopener noreferrer">
                              <div>Clique aqui</div>
                              <img
                                src={require("../../images/download.png")}
                                alt="Botão de Download"
                                height="7.5px"
                                width="7.2px"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                )}
                <div className="navbar-icons-div">
                  <NavLink
                    to="/notificacoes"
                    activeClassName="navbar-icons-single-active"
                    className="navbar-icons-single">
                    {this.state.hasNotification ? (
                      <img
                        src={require("../../images/hasnotification.svg")}
                        id="notificationbell"
                        alt="Com  Notificaçōes"
                        className="profile"
                      />
                    ) : (
                      <img
                        src={require("../../images/nonotification.svg")}
                        id="notificationbell"
                        alt="Sem  Notificaçōes"
                        className="profile"
                      />
                    )}
                  </NavLink>
                  <a
                    className={
                      this.props.location.pathname == "/profile"
                        ? "navbar-icons-single-active"
                        : "navbar-icons-single"
                    }
                    onClick={() => this.setState({ openRight: !openRight, openLeft: false })}>
                    <img src={require("../../images/user.svg")} alt="" className="profile" />
                  </a>
                </div>
              </div>
            </li>
            <li className="name">
              {/*{this.context.selectedUser.name}*/}
              {this.state.name}
              <a onClick={() => this.setState({ openRight: !openRight, openLeft: false })}>
                <img
                  src={require("../../images/expand.png")}
                  alt=""
                  style={{
                    width: "25px",
                    paddingLeft: "10px",
                  }}
                />
              </a>
            </li>
            <li className="burguer-menu">
              <div>
                {!openRight && (
                  <Drawer
                    {...drawerProps}
                    width={this.state.widthSideMenu}
                    fadeOut
                    open={openLeft}
                    onChange={(open) => this.setState({ openLeft: open })}
                    noTouchOpen={noTouchOpen}
                    noTouchClose={noTouchClose}
                    className="drawer-side-menu">
                    <Sidebar
                      className="mobile-side-bar"
                      closeMenuFunction={this.closeMenuFunction}
                      manager={this.state.manager}
                      hasManager={this.state.hasManager}
                    />
                  </Drawer>
                )}
                <a onClick={() => this.setState({ openLeft: !openLeft, openRight: false })}>
                  <img
                    src={require("../../images/burguerMenu.png")}
                    alt="Sidedrawer"
                    className="burguer-menu-icon"></img>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  async populateProfileData() {
    // console.log("nome profile", this.context.selectedUser.name);
    const profileName = this.context.selectedUser.name;
    const profileNameUTF8 = this.getNameCapitalize(this.utf8Decode(profileName));

    this.setState({ name: profileNameUTF8, loading: false });
  }

  async getTermsLink() {
    var idToken = this.context.idToken;
    const headers = {
      Authorization: `Bearer ${idToken}`,
    };
    const Url = await fetch("/Auth/GetTermsLink", { headers });
    var termsLink = await Url.text();
    this.setState({ terms: termsLink });
  }

  async getAVOApopup() {
    await new Promise((r) => setTimeout(r, 1));
    var idToken = this.context.idToken;

    const headers = {
      Authorization: `Bearer ${idToken}`,
    };
    const response = await fetch("/Elections/GetAVOADates", {
      headers,
    });
    const data = await response.json();

    this.setState({
      actualDateAVOA: data.actualDateAVOA,
      startDateAVOA: data.startDateAVOA,
      endDateAVOA: data.endDateAVOA,
    });

    let showedAVOAPopup = window.sessionStorage.getItem("showedAVOAPopup");

    var AVOAstart = new Date(data.startDateAVOA);
    var AVOAend = new Date(data.endDateAVOA);
    var AVOAactual = new Date(data.actualDateAVOA);

        if (AVOAactual > AVOAstart && AVOAactual < AVOAend) {
            if (showedAVOAPopup !== "true") {
                //faz triger do click para abrir o popup da black Friday
                document.getElementById("popupCampanhaMenoresCarolina").click();
                //decomentar isto depois
                //window.sessionStorage.setItem("showedBlackFridayPopup", "true");
            }
        }
    }

  async getNotificationStatus() {
    await new Promise((r) => setTimeout(r, 1));

    var clientId = this.context.selectedUserID;
    var idToken = this.context.idToken;

    // console.log("getNotificationStatus ", this.context);
    const headers = {
      Authorization: `Bearer ${idToken}`,
      clientId: clientId,
    };

    await fetch("/Notifications/GetNotifications?ClientId=" + clientId, {
      headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        //console.log("response Desktop GetNotifications ", response);

        function hasRead(object) {
          let a = false;
          object.forEach(function (value) {
            if (value.isRead == false) a = true;
          });
          return a;
        }

        this.setState({
          hasNotification: hasRead(response),
        });
      })
      .catch((error) => {
        //console.log("error Desktop GetNotifications ", error);

        this.setState({ hasNotification: false });
      });
  }

  async getManagerInfo() {
    await new Promise((r) => setTimeout(r, 1));

    var clientId = this.context.selectedUserID;
    // console.log("Manager ClientId: ", clientId);
    var idToken = this.context.idToken;
    const headers = {
      Authorization: `Bearer ${idToken}`,
    };
    //const response = await fetch("/Profile/GetManager", { headers });
    //if (response.status !== 200) {
    //    this.setState({ hasManager: false, loading: false });
    //} else {
    //    const data = await response.json();
    //    this.setState({ hasManager: true, manager: data, loading: false });
    //}

    //await fetch("/Profile/GetManager", {
    //    headers
    //})

    await fetch("/Profile/GetManager?ClientId=" + clientId, {
      headers,
    })
      .then((response) => {
        // console.log("Entrou no manager");

        return response.json();
      })
      .then((response) => {
        // console.log("error Desktop GetManager ", response);

        this.setState({ hasManager: true, manager: response, loading: false });
      })
      .catch((error) => {
        // console.log("error Desktop GetManager ", error);

        this.setState({ hasManager: false, loading: false });
      });
  }

  //Get Menor Associate information from MYM_CORE
  async populateAssociateData() {
    /*        await new Promise((r) => setTimeout(r, 1));*/
    var idToken = this.context.idToken;
    const headers = {
      Authorization: `Bearer ${idToken}`,
    };
    const response = await fetch("/Profile/GetMymAssociates", { headers });
    if (response.status != 200) {
      this.setState({ loading: false });
    } else {
      const data = await response.json();
      // Adicionar User do token dentro do Array de Associados!!!!!!

      fetch("/Profile/GetProfile", {
        method: "GET",
        headers,
      })
        .then((response) => {
          //console.log("response json ", response);

          // if response was 200 we convert the response to json
          // else we throw the response to the catch
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((userProfileResponse) => {
          //console.log("response profile", userProfileResponse);

          data.mymAssociates.push(userProfileResponse);

          var selectedUserClientID = "";

          data.mymAssociates.map((associated) => {
            if (associated.clientID === userProfileResponse.clientID) {
              associated.isTokenUser = true;
              selectedUserClientID = userProfileResponse.clientID;
            } else {
              associated.isTokenUser = false;
            }
          });

          //console.log("data ", data)

          this.setState({
            associates: data.mymAssociates,
            loading: false,
            selectedUserClientID: selectedUserClientID,
            isActualUser: userProfileResponse.clientID,
          });

          var montepioUser = {
            menores: data.mymAssociates,
            //tokenUserId: authentication.getUserID(), //userNIF muda conforme a pessoa selecionada, pode ser o mainNIF
            selectedUserClientID: selectedUserClientID, // NIF da pessoa do token
            isActualUser: userProfileResponse.clientID, //Sempre o da pessoa do Token => Usar para bloquear o botão "os meus dados" e cotas.
          };

          localStorage.setItem("montepioUser", JSON.stringify(montepioUser));
        });
    }
  }
}

DesktopNavbar.contextType = ContextConstructor;

export default withRouter(DesktopNavbar);
