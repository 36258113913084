import React, { Component } from "react";
import "./Highlight.css";
import ContextConstructor from "../Context/Context";

export class Highlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ad: [],
      hasAd: false,
    };
  }
  async componentDidMount() {
    this.getGlossaryLink();
  }

  render() {
    let content = this.state.hasAd ? (
      <div className="highlight-div">
        <div className="highlight-div-text">PUB</div>
        <img src={this.state.ad.media} width="100%" height="100%" alt="media"></img>
      </div>
    ) : (
      ""
    );
    return <div>{content}</div>;
  }
  async getGlossaryLink() {
    var idToken = this.context.idToken;
    const headers = { Authorization: `Bearer ${idToken}` };

    const Url = await fetch("/Wordpress/GetHighlights?page=" + this.props.page, { headers });
    if (Url.status !== "200") this.setState({ hasAd: false });
    else {
      var mediaLink = await Url.json();
      this.setState({ ad: mediaLink, hasAd: true });
    }
  }
}

Highlight.contextType = ContextConstructor;
