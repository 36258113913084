/* COMPONENT IMPORTS*/
import { AuthContext } from "../../AuthProvider";

/* MODULE IMPORTS */
import React, { Component } from "react";

export class Logout extends Component {
  static contextType = AuthContext;

  logout = () => {
    //Muda a flag que está guardada no session Storage que é responsável por mostrar o popup de eleições só 1 vez depois do login
    window.sessionStorage.setItem("showedElectionPopup", "false");

    const { msalInstance } = this.context;

    if (msalInstance) {
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: "https://www.montepio.org",
        idTokenHint: this.context.idToken,
      });
    }
  };

  render() {
    return (
      <div className="header-bar" onClick={this.logout}>
        <div className="logout-name">Sair</div>
        <img
          src={require("../../images/logout.svg")}
          alt="Logout da conta"
          width="18.4px"
          height="24px"></img>
      </div>
    );
  }
}
