/* COMPONENT IMPORTS*/
import ProductDetails from "../../components/ProductDetails/ProductDetails";
import authentication from "../../msalb2creact";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import { withRouter } from "react-router";
import React, { Component } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import Popup from "reactjs-popup";
import CurrentAccountPopUpMobile from "./CurrentAccountPopUpMobile";

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import "./tablestyling.css";
import "pure-react-carousel/dist/react-carousel.es.css";

class SavingsItemMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CanReapply: false,
      CanReinforce: false,
      collapsibleOpen: [],
      isOpened: [],
      /**actualDate: null,
            startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,**/
      actualDateAVOA: null,
      startDateAVOA: null,
      endDateAVOA: null,
      flagAVOA: false,
    };
    this.verDetalhesLink = [];
  }

  componentDidMount() {
    this.getAVOApopup();
    //this.GetBlackWeekDates();
  }

  createSubscription(item) {
    var data = {
      subscriptionID: null,
      subscriptionSerieID: null,
      canReapply: false,
      canReinforce: false,
    };
    this.props.history.push({
      pathname: "/subscricao",
      state: { params: data, modalidade: item },
    });
  }

  createSubscriptionRow(item) {
    var modalidade = null;

    this.props.savingsList
      .filter((item) => item.subscriptionsNumber > 0)
      .forEach(function (savings) {
        savings.subscriptions.forEach(function (subscription) {
          if (subscription.subscriptionID === item.subscriptionID) {
            modalidade = savings;
          }
        });
      });

    this.props.history.push({
      pathname: "/subscricao",
      state: { params: item, modalidade: modalidade },
    });
  }

  transformSubscribe(a) {
    if (a == "1") return a + " subscrição";
    else return a + " subscrições";
  }

  changeCollapsible() {
    var x = this.state.collapsibleOpen;
    this.setState({
      collapsibleOpen: !x,
    });
  }

  changeSubscribeText(id) {
    if (id == "7385") {
      return "série";
    } else {
      return "inscrição";
    }
  }

  renderDetailsPopup(index, item) {
    return (
      <Popup
        key={index}
        trigger={<div style={{ display: "none" }} id={"popupOpennerTrigger" + index}></div>}
        contentStyle={{ background: "none", border: "none" }}
        modal
        closeOnDocumentClick>
        {(close) => (
          <div className="modalidades-mobile-detalhes">
            <div className="title-section">
              <div className="title-main">
                {item.productName}
                <img
                  src={require("../../images/close.png")}
                  alt="Fechar"
                  onClick={close}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="title-subtitle">
                {this.transformSubscribe(item.subscriptionsNumber)}
              </div>
            </div>
            <div className="montantes-section">
              <div className="tableCapitalCerto-amount">
                <div className="tableCapitalCerto-givenvalue">
                  {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
                    item.totalAmounts.totalInvestedAmount,
                  )}
                </div>
                <div className="tableCapitalCerto-subtitle">Montante entregue</div>
              </div>
              <div className="tableCapitalCerto-amount">
                <div className="tableCapitalCerto-boldvalue-active">
                  {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
                    item.totalAmounts.totalReceivedAmount,
                  )}
                </div>
                <div className="tableCapitalCerto-subtitle">Rendimento acumulado Bruto</div>
              </div>
            </div>
            {item.subscriptions.map((subscription, indexSubscription) => (
              <div className="serie-mobile-wrapper">
                <div className="serie-mobile">
                  <div className="serie-mobile-first-div">
                    <ProductDetails
                      subscriptionSerieID={subscription.subscriptionSerieID}
                      productID={item.productID}
                      subscriptionsSerie={subscription.subscriptionSerie}
                      subscriptionType="serie"
                      subscriptionState={subscription.subscriptionState}
                      subscriptionNumber={subscription.subscriptionNumber}
                      currentAccount={subscription.currentAccount}
                    />
                    {subscription.canReapply ? (
                      <div className="reaplicar-reforcar-buttons-mobile">
                        <img
                          src={require("../../images/reaplicar.png")}
                          style={{ cursor: "pointer" }}
                          width="98px"
                          height="30px"
                          onClick={this.createSubscriptionRow.bind(this, subscription)}></img>
                        <CurrentAccountPopUpMobile CurrentAccountList={subscription.currentAccount}>
                          {" "}
                        </CurrentAccountPopUpMobile>
                      </div>
                    ) : subscription.canReinforce ? (
                      <div className="reaplicar-reforcar-buttons-mobile">
                        <img
                          src={require("../../images/reinforce.png")}
                          style={{ cursor: "pointer" }}
                          width="98px"
                          height="30px"
                          onClick={this.createSubscriptionRow.bind(this, subscription)}></img>{" "}
                        <CurrentAccountPopUpMobile CurrentAccountList={subscription.currentAccount}>
                          {" "}
                        </CurrentAccountPopUpMobile>
                      </div>
                    ) : (
                      <div style={{ padding: "5% 10% 0% 4%" }}>
                        {" "}
                        <CurrentAccountPopUpMobile CurrentAccountList={subscription.currentAccount}>
                          {" "}
                        </CurrentAccountPopUpMobile>{" "}
                      </div>
                    )}
                  </div>
                  <div className="subscricao-detalhe">
                    <div className="label">Montante Aplicado</div>
                    <div className="value">
                      {new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(subscription.investedAmount)}
                    </div>
                  </div>
                  <div className="subscricao-detalhe">
                    <div className="label">Rendimento Acumulado</div>
                    <div className="value acumulado">
                      {new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(subscription.receivedAmount)}
                    </div>
                  </div>
                  <div className="subscricao-detalhe">
                    <div className="label">Data Subscrição</div>
                    <div className="value data">
                      {new Intl.DateTimeFormat("pt-PT").format(
                        new Date(subscription.subscriptionDate),
                      )}
                    </div>
                  </div>
                  {item.productID == "7385" ? (
                    <div className="subscricao-detalhe">
                      <div className="label"> Data Vencimento</div>
                      <div className="value data">
                        {new Intl.DateTimeFormat("pt-PT").format(
                          new Date(subscription.subscriptionEndDate),
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
            <div className="new-serie-wrapper">
              <div
                className={
                  this.changeSubscribeText(item.productID) == "série" && this.state.flagAVOA == true
                    ? "new-series-bw"
                    : "new-series"
                }>
                <div style={{ width: "50%" }}>
                  <div
                    className={
                      this.changeSubscribeText(item.productID) == "série" &&
                      this.state.flagAVOA == true
                        ? "new-series-text-bw-mb"
                        : "new-series-text"
                    }>
                    Subscrever nova {this.changeSubscribeText(item.productID)}
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <img
                    src={require("../../images/newseries.png")}
                    onClick={this.createSubscription.bind(this, item)}
                    className="new-series-img"
                    width="50px"
                    height="50px"></img>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }

  openPopup(index) {
    //faz triger do click para abrir os detalhes especificos
    document.getElementById("popupOpennerTrigger" + index).click();
  }

  render() {
    let itemsToRender = this.props.savingsList.filter((item) => item.subscriptionsNumber > 0);

    return (
      <React.Fragment>
        {itemsToRender.map((item, index) => this.renderDetailsPopup(index, item))}

        <div className="carousel-wrapper">
          <CarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={500}
            totalSlides={this.props.nSavingsWithSubscriptions}
            visibleSlides={1}>
            <Slider>
              {itemsToRender.map((item, index) => (
                <Slide key={index} index={index}>
                  <div className="modalidades modalidades-mobile">
                    <div className="tableCapitalCerto">
                      <div className="tableCapitalCerto-orangeback">
                        <div className="tableCapitalCerto-title">{item.productName}</div>
                        <div className="tableCapitalCerto-titlesubtitle">
                          {this.transformSubscribe(item.subscriptionsNumber)}
                        </div>
                      </div>
                      <div className="tableCapitalCerto-amount">
                        <div className="tableCapitalCerto-givenvalue">
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(item.totalAmounts.totalInvestedAmount)}
                        </div>
                        <div className="tableCapitalCerto-subtitle">Montante entregue</div>
                      </div>
                      <div className="tableCapitalCerto-amount">
                        <div className="tableCapitalCerto-boldvalue-active">
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(item.totalAmounts.totalReceivedAmount)}
                        </div>
                        <div className="tableCapitalCerto-subtitle">Rendimento acumulado Bruto</div>
                      </div>
                      <div
                        id="createSubscriptionMobile"
                        onClick={this.createSubscription.bind(this, item)}>
                        <img src={require("../../images/newseries.png")}></img>
                        <div className="createSubscriptionMobile-text">Subscrever</div>
                      </div>

                      <div
                        onClick={this.openPopup.bind(this, index)}
                        className="consultar-detalhes">
                        Ver detalhe
                      </div>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
            <DotGroup />
          </CarouselProvider>
        </div>
      </React.Fragment>
    );
  }

  /**async GetBlackWeekDates() {

        const atoken = authentication.getAccessToken();

        const headers = { Authorization: `Bearer ${atoken}` };

        const response = await fetch("/Elections/GetBlackWeekDates", {

            headers,

        });

        if (response.status !== 200) {

        } else {

            const data = await response.json();


            this.setState({
                actualDate: data.actualDate,
                startDateBFTeaser: data.startDateBFTeaser,
                endDateBFTeaser: data.endDateBFTeaser,
                startDateBF: data.startDateBF,
                endDateBF: data.endDateBF,
            });

        }

    }**/
  async getAVOApopup() {
    var idToken = this.context.idToken;
    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await fetch("/Elections/GetAVOADates", {
      headers,
    });

    if (response.status !== 200) {
    } else {
      const data = await response.json();

      this.setState({
        actualDateAVOA: data.actualDateAVOA,
        startDateAVOA: data.startDateAVOA,
        endDateAVOA: data.endDateAVOA,
        flagAVOA: data.flagAVOA,
      });
    }
  }
}

SavingsItemMobile.contextType = ContextConstructor;

export default withRouter(SavingsItemMobile);
