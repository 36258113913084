import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "./custom.css";

import AuthProvider from "./AuthProvider";
import { Response } from "./pages/Response/Response";
import Layout from "./components/Layout";
import { ContextProvider } from "./components/Context/Context";
import RouteRuler from "./components/RouteRuler";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");


ReactDOM.render(
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/response/:code" component={Response} />
      <AuthProvider>
        <ContextProvider>
          <Layout>
            <RouteRuler />
          </Layout>
        </ContextProvider>
      </AuthProvider>
    </Switch>
  </BrowserRouter>,
  rootElement,
);

